import './styles.css'

import { Card, CardContent, Container, Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const colab = [
  {
    firstName: 'Fabrício',
    lastName: 'Mendes',
    imgUrl: 'fabricio.jpg',
    role: 'Fundador',
    description: 'O carinha do T.I.',
    bio: 'Apaixonado por animais desde 1992',
  }
]

const QuemSomos = () => {
  const classes = useStyles()
  return (
    <Container alignItems="center" justifContent="center" className="QuemSomos">
      <h2 className={classes.pageTitle}>Quem somos</h2>
      {
        colab.map((colab, index) =>
          <Card className={classes.card} key={index}>
            <CardContent className={classes.cardContent}>
              <Grid className={classes.photo} xs={12} sm={12} md={4} lg={4}>
                <img className={classes.img} src={colab.imgUrl} alt="" />
              </Grid>
              <Grid className={classes.colabInfo} md={8} lg={8}>
                <h2>{`${colab.firstName} ${colab.lastName}`}</h2>
                <h3>{colab.role}</h3>
                <span className={classes.description}>{colab.description}</span>
                <span>{colab.bio}</span>
              </Grid>
            </CardContent>
          </Card>
        )
      }
    </Container>
  )
}

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  card: {
    display: 'flex',
    padding: 25,
    alignItems: 'center',
  },
  cardContent: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%'
  },
  photo: {
    display: 'flex',
    justifyContent: 'center',
  },
  img: {
    width: '100%',
    borderRadius: '100%',
    maxWidth: 300,
    display: 'flex',
  },
  description: {
    display: 'block'
  },
  pageTitle: {
    display: 'flex'
  },
  colabInfo: {
    display: 'flex',
    justifyContent: 'center',
    flexFlow: 'column',
    width: '100%',
  }
}))

export default QuemSomos
