import './App.css'

import { BrowserRouter as Router } from 'react-router-dom'

import Header from './components/layout/header'
import RouterContent from './components/RouterContent'
import Footer from './components/layout/footer'
function App() {
  return (
    <div className="App">
      <Router>
        <Header />
        <RouterContent />
        <Footer />
      </Router>
    </div>
  );
}

export default App;
