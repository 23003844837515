import './styles.css'

import { Link } from 'react-router-dom'
import { Divider, Button, Hidden, Drawer, Grid } from '@material-ui/core/'
import { Menu } from '@material-ui/icons'
import { useState } from 'react'
import { makeStyles } from '@material-ui/styles'

const links = ['home', 'instituição', 'informações', 'quem somos', 'contato']

const Navbar = () => {
  const [openDrawer, setOpenDrawer] = useState(false)
  const toggleDrawer = () => { setOpenDrawer(!openDrawer) }
  const classes = useStyles();
  return (
    < nav className="Navbar" >
      <Drawer anchor="top"
        onClose={toggleDrawer}
        open={openDrawer}>
        <LinkGroup onClick={toggleDrawer} variant="vertical" />
      </Drawer>

      <Hidden className="Navbar__menu" only={['md', 'lg']}>
        <Menu onClick={toggleDrawer} />
      </Hidden>

      <Hidden only={['xs', 'sm']}>
        <LinkGroup variant="horizontal" className={classes.buttonColorWhite} />
      </Hidden>
    </nav >
  )

}


const LinkGroup = props => {
  return (
    links.map((name, index) => {
      let toLink = friendlyUrl(name)
      return (
        <Grid style={{display: 'flex'}} justifyContent="center" key={`${index}${props.variant}`}>
          <Button className={props.className}
            onClick={props.onClick}
            href={toLink}
            to={toLink}
            component={Link}
            key={`but-${index}-${props.variant}`}>{name}</Button>
          {(index < links.length - 1) && <Divider
            flexItem
            orientation={(props.variant === 'vertical' ? 'horizontal' : 'vertical') || 'vertical'}
          />
          }
        </Grid>
      )
    }))
}

const useStyles = makeStyles((theme) => ({
  buttonColorWhite: {
    color: 'white',
  },
}));

const friendlyUrl = url =>
  url.replaceAll(' ', '')
    .replaceAll('ç', 'c')
    .replaceAll(/[ã,á,à,â]/img, 'a')
    .replaceAll(/[é,ê]/img, 'e')
    .replaceAll('í', 'i')
    .replaceAll(/[õ,ó]/img, 'o')
    .replaceAll('ú', 'u')
    .toLowerCase()

export default Navbar