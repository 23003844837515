import { Switch, Route } from 'react-router-dom'
import './styles.css'
import Home from './pages/home'
import QuemSomos from './pages/quem-somos'

const RouterContent = () =>
  <div className="RouterContent">
    <Switch>
      <Route exact path={['/', '/home']}>
        <Home />
      </Route>
      <Route path="/quemsomos">
        <QuemSomos />
      </Route>
      <Route path="*">
        <Home />
      </Route>
    </Switch>
  </div>

export default RouterContent