import Wave from 'react-wavify'

import { Grid, Hidden } from '@material-ui/core'
import Navbar from './components/Navbar'

import './styles.css'
import headerPhoto from '../../../assets/images/header-photo.png'

const Header = () => {
  return (
    <div className="Header">
      <Navbar />
      <Grid container justifyContent="center" lg={12}>
        <Grid className="Header__title" justifyContent="center" xs={12} sm={12} md={8} lg={8}>
          <h1>Pet Pet House </h1>
        </Grid>
        <Hidden only={['xs', 'sm']}>
          <Grid container className="Header__header-photo"
            justifyContent="center"
            alignItems="center"
            md={4}
            lg={3}>
            <img src={headerPhoto} alt="Pug" />
          </Grid>
        </Hidden>
      </Grid>
      <Wave className="wave" fill='#fafafa'
        paused={false}
        options={{
          height: 10,
          amplitude: 50,
          speed: 0.07,
          points: 5
        }}
      />
    </div>
  )
}

export default Header